import React, { FC } from "react";
import { type MicroCardsProps } from "../generated-types";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Flex } from "@chakra-ui/react";
import { MicroCard } from "./MicroCard";
import { themable } from "~/foundation/Theme";

const MicroCards: FC<MicroCardsProps> = ({ fields, rendering }) => {
	const microCards = (rendering?.customData?.microCards ?? []).filter(Boolean);

	return (
		<Ribbon
			pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			pb={["var(--chakra-sizes-modulePY__SM)", "calc(var(--chakra-sizes-modulePY__SM) - 3.5rem)", "calc(var(--chakra-sizes-modulePY__MD) - 3.5rem)", null, "calc(var(--chakra-sizes-modulePY__XL) - 3.5rem)"]}
		>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper py={["0", null]}>
				<Flex flexWrap="wrap" gap={["12", "5"]} justifyContent="center">
					{microCards.map((microCard, index) => (
						<MicroCard key={index} item={microCard} />
					))}
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(MicroCards);