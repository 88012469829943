import React, { FC, useContext, useEffect, useState } from "react";
import { AspectRatio, Box, Heading, Text as ChakraText } from "@chakra-ui/react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { imageSizes } from "~/foundation/Theme";
import { ExtendedBox } from "~/foundation/Components/ExtendedBox";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { MicroCardModel } from "../generated-types";
import { useTranslation } from "~/foundation/Dictionary";
import { MediaDictionary } from "../dictionary";
import { ChakraRouterLink } from "~/foundation/Components/Link/ChakraRouterLink";
import { getLinkVariants } from "~/foundation/Theme/components/Link";
import LinkIconWrapper from "~/foundation/Components/Link/LinkIconWrapper";

type MicroCardProps = {
	item: MicroCardModel;
}

export const MicroCard: FC<MicroCardProps> = ({ item }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const linkVariants = getLinkVariants(isRtl);
	const [theme] = useContext(ColorThemeContext);
	const [t] = useTranslation<MediaDictionary>();
	const [cardActive, setCardActive] = useState(false);
	const [imageScale, setImageScale] = useState(1);

	if (!sitecoreContext.pageEditing && item.link?.value.href && (!item.link?.value.text || item.link?.value.text === '')) {
		item.link.value.text = t("media.microCards.visitNow");
	}

	if (!item.image?.value?.src) {
		item.image = {
			value: {
				src: sitecoreContext.custom.settings.imageFallback,
				alt: ""
			}
		}
	}

	const handleMicroCardEvent = (activeState: boolean) => {
		setCardActive(activeState)

		if (item.image?.value) {
			if (activeState) {
				setImageScale(1.025);
			} else {
				setImageScale(1);
			}
		}
	}

	useEffect(() => {
		handleMicroCardEvent(cardActive);
	},[])

	return (
		<Box flexBasis={["100%", "calc(50% - .625rem)", null, "calc(33.333% - .833rem)"]} color={theme.colors.ribbon.color} pb={["0","14"]}>
			<ChakraRouterLink
				as={(sitecoreContext.pageEditing || !item.link?.value.href) ? "span" : "a"}
				variant="unstyled"
				display="block"
				{...(!sitecoreContext.pageEditing && {
					to: item.link?.value.href,
				})}
				{...(!sitecoreContext.pageEditing && {
					onMouseEnter: () => handleMicroCardEvent(true),
					onMouseLeave: () => handleMicroCardEvent(false),
				})}
			>
				{(item.image?.value || sitecoreContext.pageEditing) && (
					<Box mb={["4", null, "6"]}>
						<AspectRatio ratio={16 / 9} sx={{
							".LazyLoad": {
								borderRadius: ".92rem",
								borderBottomRightRadius: "0",
								WebkitMaskImage: "-webkit-radial-gradient(white, black)", // Safari fix
								"img": {
									transform: `scale(${imageScale.toString()})`,
								}
							}
						}}>
							<sc.AdvanceImage
								editable={item.editable}
								fields={item}
								itemId={item.id}
								fieldName="image"
								sizes="(min-width: 992px) 445px, (min-width: 480px) 381px, 100vw"
								srcSet={[imageSizes.microCard.landscape.sm, imageSizes.microCard.landscape.md]}
								sx={{
									maxWidth: "100%",
									minWidth: "100%",
									transitionProperty: "transform",
									transitionDuration: ".5s",
									transitionTimingFunction: "aramcoCubic"
								}}
							/>
						</AspectRatio>
					</Box>
				)}
				{(item.headline?.value || sitecoreContext.pageEditing) &&
				<Heading as="h3" size="xs" mt="2" noOfLines={1}>
					<sc.Text field={item.headline} editable={item.editable} />
				</Heading>
				}
				{(item.description?.value || sitecoreContext.pageEditing) &&
				<ChakraText size="body" mt="2" noOfLines={3}>
					<sc.Text field={item.description} editable={item.editable} />
				</ChakraText>
				}
				{sitecoreContext.pageEditing && !item.referenceLinkText &&
					<ExtendedBox mt="9">
						<sc.Link field={item.link} variant="secondary" size={["md", null, "lg"]} color={theme.colors.ribbon.linkColor} editable={item.editable}/>
					</ExtendedBox>
				}
				{sitecoreContext.pageEditing && item.referenceLinkText &&
					<ExtendedBox mt="9" display="inline-flex">
						{item.link?.value &&
							<LinkIconWrapper linkValue={item.link.value} rtl={isRtl} w={["icons.md", null, "icons.lg"]} me="5"/>
						}
						<sc.Text field={item.referenceLinkText} editable={item.editable}/>
					</ExtendedBox>
				}
				{(item.link?.value.href && !sitecoreContext.pageEditing) &&
					<ExtendedBox mt="9" sx={linkVariants.secondary} color={theme.colors.ribbon.linkColor}>
						<LinkIconWrapper linkValue={item.link.value} rtl={isRtl} w={["icons.md", null, "icons.lg"]} me="5"/>
						<span>{item.link.value.text}</span>
					</ExtendedBox>
				}
			</ChakraRouterLink>
		</Box>
	);
};